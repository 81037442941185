import React, { useState, useEffect,useRef } from "react";
import MintV2 from './MintVer2';


const MyComponent = (MsgSender) => {
    
    const [sayAnswer, setSayAnswer] = useState(null);
    const audioRef = useRef(null);
    const [isClickedHello, setHello] = useState(false);
    const [PepeAnswer, setPepeAnswer] = useState("");
    

    const audioArray = [
        "https://grandpepe.com/sound/hello1.mp3", 
        "https://grandpepe.com/sound/hello2.mp3",
        "https://grandpepe.com/sound/hello3.mp3",
        "https://grandpepe.com/sound/hello4.mp3",
        "https://grandpepe.com/sound/hello5.mp3",
        "https://grandpepe.com/sound/hello6.mp3",
        "https://grandpepe.com/sound/hello7.mp3",
        "https://grandpepe.com/sound/hello8.mp3",
        "https://grandpepe.com/sound/hello9.mp3",
        "https://grandpepe.com/sound/hello10.mp3",
        "https://grandpepe.com/sound/hello11.mp3",
        "https://grandpepe.com/sound/hello12.mp3",
        "https://grandpepe.com/sound/hello13.mp3",
        "https://grandpepe.com/sound/hello14.mp3",
        "https://grandpepe.com/sound/hello15.mp3",
        "https://grandpepe.com/sound/hello16.mp3",
        "https://grandpepe.com/sound/hello17.mp3",
        "https://grandpepe.com/sound/hello18.mp3",
        "https://grandpepe.com/sound/hello19.mp3",
        "https://grandpepe.com/sound/hello20.mp3",
        "https://grandpepe.com/sound/hello21.mp3",
        "https://grandpepe.com/sound/hello22.mp3",
        "https://grandpepe.com/sound/hello23.mp3",
        "https://grandpepe.com/sound/hello24.mp3",
        "https://grandpepe.com/sound/hello25.mp3",
        "https://grandpepe.com/sound/hello26.mp3",
        "https://grandpepe.com/sound/hello27.mp3",
        "https://grandpepe.com/sound/hello28.mp3",
        "https://grandpepe.com/sound/hello29.mp3",
        "https://grandpepe.com/sound/hello30.mp3",
      ];

////////////////////////Animation/////////////////////
function  animateText(element) {
  const string = element.innerHTML;
  element.innerHTML = string.replace(/./g, '<span class="new">$&</span>');

  const spans = element.querySelectorAll('span.new');
  spans.forEach((el, i) => {
    setTimeout(() => {
      el.classList.add('div_opacity');
    }, 70 * i);
  });
}
  /////////////////////////////////////////////////////////
  function UserClickHello() {
    const hours = new Date().getHours();
    let helloAnswer = "Good day for one question";
    let newAudioIndex = 0;
   

    const getRandom = (max) => Math.round(Math.random() * max);

    if (hours >= 13 && hours <= 19) {
      helloAnswer = "Good day\nfor one question";
      newAudioIndex = 1;

      const rnd = getRandom(6);
      if (rnd === 1) {
        newAudioIndex = 2;
        helloAnswer = "Have you seen\nthe weather outside?\nOk ask me your question";
      } else if (rnd === 2) {
        newAudioIndex = 3;
        helloAnswer = "It\'s a beautiful\nday for predictions\nJust ask me...";
      } else if (rnd === 3) {
        newAudioIndex = 4;
        helloAnswer = "What a great day,\nhow are you? \nYou can ask me now...";
      }
    } else if (hours >= 20 || hours <= 5) {
      helloAnswer = hours <= 5 ? "Quiet night\nAsk one question" : "Good evening\nAsk one question";
      newAudioIndex = hours <= 5 ? 9 : 5;
      

      const rnd = getRandom(6);
      if (rnd === 1) {
        newAudioIndex = hours <= 5 ? 10 : 6;
        helloAnswer = hours <= 5
          ? "You can\'t sleep?\nWell, ask me\nyour question..."
          : "What a quiet evening\nOk ask me your question";
      } else if (rnd === 2) {
        newAudioIndex = hours <= 5 ? 11 : 7;
        helloAnswer = hours <= 5
          ? "Sometimes it\'s \nbetter to go to bed.\nOk ask me your question..."
          : "Why am I tired enough\nwhen sleep all day?\nOk ask me your question";
      } else if (rnd === 3) {
        newAudioIndex = hours <= 5 ? 12 : 8;
        helloAnswer = hours <= 5
          ? "You actually\nwoke me up.\nSo ask your question..."
          : "I\'m already \ngetting ready for bed.\nOk ask me your question";
      }
    } else if (hours >= 6 && hours <= 12) {
      helloAnswer = "Good morning\nAsk one question";
      newAudioIndex = 13;

      const rnd = getRandom(6);
      if (rnd === 1) {
        newAudioIndex = 14;
        helloAnswer = "What a cool morning!\nOk ask me your question";
      } else if (rnd === 2) {
        newAudioIndex = 15;
        helloAnswer = "Have you\nmet the sunrise?\nAsk me your question...";
      }
    }

    // Дополнительный блок случайных ответов
    const rndMain = getRandom(35);
    if (rndMain <= 14) {
      const randomAnswers = [
        "Fuck this AI art!\n...have a question?",
        "Hypno FROG makes\nyou buy it?..\nwhat you want?",
        "Blue chip is not cheap...\nwhat you whant?",
        "Generation degeneration...\nyou have a question?",
        "Shut up and buy some NFT...\nmore questions?",
        "Tag 3 friends and next answer for free\nthan you can ask me",
        "GM I have a GEM!\n...have a question?",
        "The Grand Pepe at your service\nAsk your question!",
        "Your face when you ask NFT what to do\n...ok what you want?",
        "Today is a bad day\n...Ask and go away",
        "I wish I was APE but\n...ok what you want?",
        "You have nft what next?...\nOk, your question is?",
        "You dont want sex\nYou want the answer\njust ask me..",
        "AM I LOOKS RARE?\n...Ok what is your question?",
        "SHUT UP\nAnd change your PFP\nMore questions?",
      ];
      helloAnswer = randomAnswers[rndMain];
      newAudioIndex = 16 + rndMain;
    }
    
    setPepeAnswer(helloAnswer);
    
    console.log(helloAnswer , newAudioIndex );
    setSayAnswer(audioArray[newAudioIndex-1]);
    
     setHello(true);
    
  };

  useEffect(() => {
    const iframe = document.querySelector('iframe'); 
    if (iframe && iframe.contentDocument) {
        if (PepeAnswer!=='') {
      //iframe.contentDocument.getElementById('startt').style.visibility = 'hidden';
      
     // iframe.contentDocument.getElementById('startt').innerHTML = PepeAnswer;
      iframe.contentDocument.getElementById('startt').style.whiteSpace = 'pre-line';
      iframe.contentDocument.getElementById('startt').textContent = PepeAnswer;


      //iframe.contentDocument.getElementById('startt').style.visibility = 'visible';
      //iframe.contentDocument.getElementById('startt').style.alignContent = 'normal';
        
                      
                      const exampleElement = iframe.contentDocument.getElementById('startt');
                      animateText(exampleElement);
                      
                                          }}
      } , [PepeAnswer]); 



  
 

  useEffect(() => {
    //AutoplayAudio
    if (sayAnswer && audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Cant play audio:", error);
      });
    }
  }, [sayAnswer]);

 
 

  
  return (
    <div className='askDiv'>

      
      {!isClickedHello &&  <button className='w-button-ask' onClick={() => UserClickHello()} disabled={ isClickedHello}>
        SAY HELLO
      </button>}

      {isClickedHello && (<MintV2></MintV2> )}
      
      
    {sayAnswer && (
      <audio className='audio' ref={audioRef} controls>
        <source src={sayAnswer} type="audio/mp3" />        
      </audio> )}
    </div>
  );
};

export default MyComponent;
